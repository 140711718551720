import React from 'react';
import {Button} from 'react-bootstrap';

import FlightForm from './FlightForm';
import {closeAlert, displayErrorMessage} from '../../../../packs/helpers/alerts';

const FlightsForm = (props) => {
  const {message, formName, formValues, handleChange, stations, formValid, defaultFlight} = props;

  const addFlight = () => {
    const [valid] = formValid();
    if (valid) {
      closeAlert();
      handleChange(formName, (oldFlights) => (oldFlights.concat({...defaultFlight})));
    } else {
      displayErrorMessage('Please Fill out all Flight Data before adding a new one.');
    }
  };

  const removeFlight = (index) => {
    handleChange(formName, (oldFlights) => {
      const newFlights = Array.from(oldFlights);
      newFlights.splice(index, 1);
      return newFlights;
    });
  };

  const handleChangeFlight = (index, name, value) => {
    handleChange(formName, (oldFlights) => {
      const newFlights = Array.from(oldFlights);
      newFlights[index][name] = value;
      return newFlights;
    });
  };

  return (
    <div>
      <p className='text-muted'>
        {message}
      </p>

      {formValues[formName].map((flight, index) => (
        <FlightForm key={index} formValues={flight} stations={stations}
                    handleChange={(name, value) => handleChangeFlight(index, name, value)}
                    handleRemoveFlight={index !== 0 ? () => removeFlight(index) : null}
        />
      ))}

      <Button variant='success' onClick={addFlight}>Add Flight</Button>
    </div>
  );
};

export default FlightsForm;
