import React from 'react';

import {Row, Col} from 'react-bootstrap';

const AirportForm = (props) => {
  const {stations, formValues, handleChange} = props;

  const displayExcludeDuration = () => {
    let time = parseFloat(formValues.excludeDuration);
    let hours = Math.floor(time);
    let min = (time - hours) * 60;
    return `${hours ? `${hours} hours ` : ''}${min ? `${min} minutes` : ''}`;
  };

  return (
    <div>
      <div>
        <div className="form-group select required airport_station">
          <label className="select required">
            Departure airport <abbr title="required">*</abbr>
          </label>
          <select 
            className="form-control select required"
            onChange={(event) => handleChange('station', event.target.value)}
          >
            {stations.map((station, index) => (
              <option value={station} key={index}>{station}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="input-group mb-3 w-100">
        <label className="range required container w-100 p-0">
          <Row>
            <Col sm={4} className="col-12">
              Flights to Notify <abbr title="required">*</abbr>
            </Col>

            <Col sm={8} className="col-12 text-sm-end">
              <span className='align-right w-100'>
                Departing Flights {displayExcludeDuration()} from now to midnight
              </span>
            </Col>
          </Row>
        </label>
        <div className="w-100 form-group range required airport_exclude_duration">
          <input className="form-control-range numeric range required form-range w-100"
                 min="0" max="4" step="0.25" value={formValues.excludeDuration}
                 onChange={(event) => handleChange('excludeDuration', event.target.value)}
                 required="required" aria-required="true" type="range"
          />
        </div>
      </div>
    </div>
  );
};

export default AirportForm;
