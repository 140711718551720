import React, {useState, useEffect} from 'react';

import Recipients from './Recipients';
import Messages from './Messages';
import NotificationTool from '../NotificationTool';

const CustomerNotificationTool = (props) => {
  const {templates, selectedTemplate, stations, authenticityToken} = props;

  const defaultFlight = {
    origin: stations[0],
    destination: stations[0]
  };

  const defaultAirport = {
    station: stations[0],
    excludeDuration: '0.5'
  };

  const defaultFormValues = {
    notifyBy: 'pnrs',
    pnrs: '',
    flights: [{...defaultFlight}],
    airport: defaultAirport,
    thruFlights: [{...defaultFlight}],
    sendEmail: false,
    sendPush: false,
    sendText: false,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const onSubmitPressed = () => {
    return fetch('/notifications/send_messages', {
      method: 'POST', mode: 'cors', cache: 'no-cache', headers: {
        'Content-Type': 'application/json', 'X-CSRF-Token': authenticityToken
      }, body: JSON.stringify(formValues)
    });
  };

  const onTemplateSelected = (template) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      eventName: template?.template_name || '',

      emailSubject: template?.email_subject || '',
      emailBody: template?.email_body?.body || '',

      pushSubject: template?.push_subject || '',
      pushClickAction: template?.push_click_action || '',
      pushBody: template?.push_body || '',

      textMessage: template?.text_message || ''
    }));
  };

  useEffect(() => {
    if (selectedTemplate) {
      onTemplateSelected(selectedTemplate);
    }
  }, []);

  return (
    <NotificationTool
      title='Notification Tool'
      documentation='notifications'
      localStorageName='notificationToolValues'
      formValues={formValues}
      setFormValues={setFormValues}
      Recipients={Recipients}
      recipientProps={{stations, defaultFlight}}
      Messages={Messages}
      messageProps={{templates, onTemplateSelected}}
      defaultFormValues={defaultFormValues}
      onSubmit={onSubmitPressed}
    />
  );
};

export default CustomerNotificationTool;
