// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import * as bootstrap from 'bootstrap'
import '../stylesheets/application'

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// make bootstrap js available (used for toasts/push notifications)
global.bootstrap = require('bootstrap');

require('trix');
require('@rails/actiontext');

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require('jquery');

require('jquery');
require('jquery-ui');

// prevent the use of attachments on action-text/trix
window.addEventListener('trix-attachment-add', function(event) {
  event.preventDefault();
  alert('File attachment not supported!');
});

// prevent the use of attachments on action-text/trix
window.addEventListener('trix-file-accept', function(event) {
  event.preventDefault();
  alert('File attachment not supported!');
});

document.addEventListener('DOMContentLoaded', function(event) {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle=\'tooltip\']'));
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
