import React from 'react';

import RecipientForms from './forms/RecipientForms';
import {displayErrorMessage} from '../../../packs/helpers/alerts';

import {Button, Nav} from 'react-bootstrap';

const Recipients = (props) => {
  const {handleChange, formValues, onResetPressed, onNextPressed, defaultFlight, stations} = props;

  const validFlight = (flight) => {
    return flight.flightDate && flight.origin && flight.destination && flight.flightNumber?.length > 0;
  };

  const formValid = () => {
    switch (formValues.notifyBy) {
      case 'pnrs':
        return [formValues.pnrs?.length > 0, 'Please add at least one pnr'];
      case 'flights':
        return [
          formValues.flights.every(validFlight),
          'Please fill out all Flight Data.'
        ];
      case 'airport':
        return [true];
      case 'thruFlights':
        return [
          formValues.thruFlights.every(validFlight),
          'Please fill out all Flight Data.'
        ];
      default:
        return [false, 'Form Not Valid'];
    }
  };

  const handleNextPressed = () => {
    const [valid, error] = formValid();
    if (!valid) {
      displayErrorMessage(error);
      return;
    }

    onNextPressed();
  };

  const handleSelectTab = (value) => {
    handleChange('notifyBy', value);
  };

  return (
    <div>
      <Nav variant='tabs' activeKey={formValues.notifyBy} onSelect={handleSelectTab}>
        <Nav.Item>
          <Nav.Link eventKey='pnrs'>By PNRs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='flights'>By Flights</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='airport'>By Airport</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='thruFlights'>By Thru Flights</Nav.Link>
        </Nav.Item>
      </Nav>

      <div className='m-2'>
        <div className='mt-1 mb-1'>
          <RecipientForms formValues={formValues} handleChange={handleChange} stations={stations}
                          defaultFlight={defaultFlight} formValid={formValid}/>
        </div>

        <div>
          <Button variant='secondary' onClick={onResetPressed} className='m-1 ms-0'>Reset Form</Button>
          <Button onClick={handleNextPressed} className='m-1'>Next</Button>
        </div>
      </div>

      <div className="text-center text-muted mt-5">
        To view all previously sent notifications or notification templates. Please use the link below.
      </div>

      <div className="d-flex justify-content-center p-2">
        <a className='btn btn-primary' href='/notifications/history'>View Notifications History</a>
      </div>

      <div className="d-flex justify-content-center">
        <a className='btn btn-primary' href='/notification_templates'>View Notification Templates</a>
      </div>

    </div>
  );
};

export default Recipients;
