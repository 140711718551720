import React, {useEffect, useState} from 'react';
import {Modal, Col, Row, Spinner, Alert, Button} from 'react-bootstrap';
import dayjs from 'dayjs';

const FlightSsrModal = (props) => {
  const {show, handleClose, flight} = props;

  const ssrToShow = ['BLND', 'DEAF', 'DPNA', 'MAAS', 'WCBD', 'WCBW', 'WCHR', 'WCHC', 'WCOB', 'WCHS', 'MEDA'];

  const [ssr, setSsr] = useState({});
  const [savedSsr, setSavedSsr] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const saveFlightSsrs = (uniqueId, ssr) => {
    setSavedSsr((oldValues) => ({...oldValues, [uniqueId]: ssr}));
  };

  const retrieveFlightSsrs = (flight) => {
    let uniqueId = flight.uniqueId;

    setLoading(true);
    setError(false);

    let flightDate = flight.stdLocal.split('T')[0];
    let origin = flight.scheduledDepartureAirport;
    let destination = flight.scheduledArrivalAirport;
    let flightNumber = flight.flightNumber;

    fetch(`/ssr_portal/flight_ssr?origin=${origin}&destination=${destination}&flight_number=${flightNumber}&flight_date=${flightDate}`)
      .then((response) => (response.json()))
      .then((result) => {
        if (result) {
          saveFlightSsrs(uniqueId, result);
          setSsr(result);
        } else {
          if (show) {
            setError(true);
          }
        }
      })
      .catch((error) => {
        if (show) {
          setError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getSsrForFlight = (flight) => {
    let uniqueId = flight.uniqueId;

    if (savedSsr[uniqueId]) {
      setSsr(savedSsr[uniqueId]);
      return;
    }

    retrieveFlightSsrs(flight);
  };

  const refreshSsrs = (flight) => {
    let uniqueId = flight.uniqueId;

    saveFlightSsrs(uniqueId, null);
    retrieveFlightSsrs(flight);
  }

  useEffect(() => {
    if (show && flight && Object.keys(flight).length) {
      getSsrForFlight(flight);
    }
  }, [flight, show]);

  const formatDate = (date) => {
    return dayjs(date).format('MM/DD/YYYY');
  };

  const formatTime = (date) => {
    return dayjs(date).format('hh:mm A');
  };

  const handleHide = () => {
    handleClose();
    setError(false);
  }

  return (
    <Modal show={show} onHide={handleHide} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Flight # {flight.flightNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div>
            {flight.scheduledDepartureAirport} to {flight.scheduledArrivalAirport}<br/>
            {formatDate(flight.stdLocal)}: {formatTime(flight.stdLocal)} - {formatTime(flight.staLocal)}
          </div>
          <div>
            <Button onClick={() => refreshSsrs(flight)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                <path
                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
              </svg>
            </Button>
          </div>
        </div>
        <Row className='mt-1'>
          {error ?
            <Alert variant='danger'>Failed to load flight SSRs</Alert>
            : ssrToShow.map((ssrName) => (
              <Col xs={6} key={ssrName}>
                {ssrName}: {loading ?
                <Spinner animation='grow' size='sm' style={{color: 'lightblue'}}/> : ssr[ssrName] || 0
              }
              </Col>
            ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FlightSsrModal;
