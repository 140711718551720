import React, {useState} from 'react';

import AirportForm from './AirportForm';
import Flights from './Flights';
import FlightSsrModal from './FlightSsrModal';
import {Col, Row} from 'react-bootstrap';

const SsrPortal = (props) => {
  const {stations} = props;

  const defaultFlights = {
    departing: [],
    arriving: []
  };

  const [flights, setFlights] = useState(defaultFlights);

  const [modalShow, setModalShow] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState({});

  const selectFlight = (flight) => {
    setSelectedFlight(flight);
    setModalShow(true);
  };

  return (
    <div>
      <FlightSsrModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        flight={selectedFlight}
      />

      <h1>
        SSR Portal
      </h1>
      <div>
        <AirportForm setFlights={setFlights} stations={stations}/>
      </div>
      <Row className='mt-2'>
        <Col className='col-12 col-lg-6'>
          <h3>Departing Flights</h3>
          <Flights flights={flights.departing} selectFlight={selectFlight} departing/>
        </Col>
        <Col className='col-12 col-lg-6'>
          <h3>Arriving Flights</h3>
          <Flights flights={flights.arriving} selectFlight={selectFlight}/>
        </Col>
      </Row>
    </div>
  );
};

export default SsrPortal;
