import React from 'react';

const Progressbar = (props) => {
  const {steps, currentStep} = props;

  return(
    <ul id='progressbar'>
      {steps.map((step, index) => (
        <li key={index} className={currentStep >= index ? 'active' : null} id={step.type || step.name}>
          <strong>{step.name}</strong>
        </li>
      ))}
    </ul>
  );
};

export default Progressbar;
