import React from 'react';

const AutocompleteSuggestionList = (props) => {
  const { suggestions, showSuggestions, activeSuggestion, handleOnClick } = props;

  if (!showSuggestions) {
    return <></>
  }

  if (suggestions.length === 0) {
    return (
      <ul className="autocomplete-suggestions">
        <li className="no-hover">No suggestions available. Try something else.</li>
      </ul>
    )
  }

  return (
    <ul className="autocomplete-suggestions">
      {
        suggestions.map((suggestion, index) => {
          let className;

          if (index === activeSuggestion) {
            className = 'autocomplete-suggestion-active';
          }

          return (
            <li key={index} onClick={handleOnClick} data-value={suggestion.value} className={className}>
              {suggestion.label}
            </li>
          )
        })
      }
    </ul>
  )
}

export default AutocompleteSuggestionList;
