import React from 'react';
import MessageForm from '../MessageForm';
import DisplayRecipients from './DisplayRecipients';

const Messages = (props) => {
  const {
    formValues,
    handleChange,
    templates,
    onTemplateSelected,
    onBackPressed,
    onSubmitPressed,
    onResetPressed,
    submitting
  } = props;

  const supportedVariables = () => {
    switch (formValues.notifyBy) {
      case 'pnrs':
        return ([
          {label: "Guest's First Name", value: 'first_name', example: 'Ethan'},
          {label: 'Confirmation Number', value: 'pnr', example: 'ABC123'},

          {label: 'Outbound Flight Number', value: 'outbound_flight_number', example: '200'},
          {label: 'Outbound Departure Date', value: 'outbound_departure_date', example: 'January 1, 2021'},
          {label: 'Outbound Departure Time', value: 'outbound_departure_time', example: '10:00 AM'},
          {label: 'Outbound Arrival Date', value: 'outbound_arrival_date', example: 'January 1, 2021'},
          {label: 'Outbound Arrival Time', value: 'outbound_arrival_time', example: '3:00 PM'},
          {label: 'Outbound Departure City', value: 'outbound_departure_city', example: 'Salt Lake City, UT'},
          {label: 'Outbound Arrival City', value: 'outbound_arrival_city', example: 'Provo, UT'},

          {label: 'Return Flight Number', value: 'return_flight_number', example: '201'},
          {label: 'Return Departure Date', value: 'return_departure_date', example: 'January 2, 2021'},
          {label: 'Return Departure Time', value: 'return_departure_time', example: '11:00 AM'},
          {label: 'Return Arrival Date', value: 'return_arrival_date', example: 'January 2, 2021'},
          {label: 'Return Arrival Time', value: 'return_arrival_time', example: '5:00 PM'},
          {label: 'Return Departure City', value: 'return_departure_city', example: 'Provo, UT'},
          {label: 'Return Arrival City', value: 'return_arrival_city', example: 'Salt Lake City, UT'}
        ]);
      default:
        return ([
          {label: "Guest's First Name", value: 'first_name', example: 'Ethan'},
          {label: 'Confirmation Number', value: 'pnr', example: 'ABC123'},
          {label: 'Flight Number', value: 'flight_number', example: '200'},
          {label: 'Departure Date', value: 'departure_date', example: 'January 1, 2021'},
          {label: 'Departure Time', value: 'departure_time', example: '10:00 AM'},
          {label: 'Arrival Date', value: 'arrival_date', example: 'January 1, 2021'},
          {label: 'Arrival Time', value: 'arrival_time', example: '3:00 PM'},
          {label: 'Departure City', value: 'departure_city', example: 'Salt Lake City, UT'},
          {label: 'Arrival City', value: 'arrival_city', example: 'Provo, UT'}
        ]);
    }
  };

  return (
    <MessageForm
      formValues={formValues} handleChange={handleChange} submitting={submitting}
      onBackPressed={onBackPressed} onSubmitPressed={onSubmitPressed} onResetPressed={onResetPressed}
      messageTypes={['text', 'email', 'push']} supportedVariables={supportedVariables()}
      templates={templates} onTemplateSelected={onTemplateSelected} templatesUrl={'/notification_templates'}
      supportedVariableProps={{
        description:
          <>
            {formValues.notifyBy === 'thruFlights' &&
              <div className="text-muted">
                'Departure' refers to the first leg while 'Arrival' refers to the last
              </div>
            }
            <div>
              The following variables can be used in any subject or message.
              {formValues.notifyBy === 'pnrs' &&
                <span>
                  'Outbound' refers to the first journey on a booking. 'Return' refers to the second journey of a booking.
                </span>
              }
              See <a href="/documentation/notifications#message-variables">Message Variables</a> in the documentation for more information.
            </div>
          </>,
        example:
          <p className="mb-0">
            Example:
            <em>"Hi {'{{name}}'}, we have new information regarding your flight, confirmation
              number: {'{{pnr}}'}"</em>.
            Will result in:
            <em>"Hi Ethan, we have new information regarding your flight, confirmation number: ABC123"</em>
          </p>
      }}
    >
      <div className="form-group string required notification_event_name form-group-valid">
        <label className="string required">
          Event <abbr title="required">*</abbr>
        </label>
        <input
          className="form-control is-valid string required"
          required="required" aria-required="true" type="text"
          value={formValues.eventName || ''}
          onChange={(event) => handleChange('eventName', event.target.value)}
          id="notification_event_name"/>
        <small className="form-text text-muted">
          Reason for the notification. Example: "Hurricane Elsa" This will NOT been seen by the guest.
        </small>
      </div>

      <DisplayRecipients formValues={formValues}/>

    </MessageForm>
  );
};

export default Messages;
