import React, {useEffect, useState} from 'react';

import DocumentationLinkIcon from '../shared/DocumentationLinkIcon';
import Progressbar from '../shared/Progressbar';
import {displaySuccessMessage, displayErrorMessage, closeAlert} from '../../packs/helpers/alerts';

const NotificationTool = (props) => {
  const {
    title,
    documentation,
    Messages,
    messageProps,
    Recipients,
    recipientProps,
    formValues,
    setFormValues,
    defaultFormValues,
    onSubmit,
    localStorageName,
    aircraftTypesProps
  } = props;

  const steps = [{name: 'recipients'}, {name: 'messages'}, {name: 'send'}];

  const [currentStep, setCurrentStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const onResetPressed = () => {
    setFormValues(defaultFormValues);
    setCurrentStep(0);
  };

  const onNextPressed = () => {
    closeAlert();
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const onBackPressed = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onSubmitPressed = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    onSubmit().then((response) => {
      if (response.status >= 200 && response.status < 300) {
        onResetPressed();
        displaySuccessMessage('Notifications Sent');
      } else {
        return response.json();
      }
    }).then((response) => {
      if (response) {
        displayErrorMessage(`Failed to send notifications: \n${response.message.join('\n')}`);
      }
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const handleChange = (name, value) => {
    setFormValues((oldValues) => ({
      ...oldValues, [name]: typeof (value) === 'function' ? value(oldValues[name]) : value
    }));
  };

  useEffect(() => {
    let storedData = window.localStorage.getItem(localStorageName);
    if (storedData) {
      setFormValues(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (formValues) {
      window.localStorage.setItem(localStorageName, JSON.stringify(formValues));
    }
  }, [formValues]);

  return (<div>
    <div id='flash-alerts'/>

    <h1>
      {title} <DocumentationLinkIcon article={documentation} placement='right'/>
    </h1>
    <Progressbar steps={steps} currentStep={currentStep}/>
    {currentStep === 0 &&
      <Recipients
        {...recipientProps}
        formValues={formValues}
        handleChange={handleChange}
        onResetPressed={onResetPressed}
        onNextPressed={onNextPressed}
        aircraftTypes={aircraftTypesProps}
      />
    }
    {currentStep === 1 &&
      <Messages
        {...messageProps}
        formValues={formValues}
        handleChange={handleChange}
        onResetPressed={onResetPressed}
        onBackPressed={onBackPressed}
        onSubmitPressed={onSubmitPressed}
        submitting={submitting}
      />
    }
  </div>);
};

export default NotificationTool;
