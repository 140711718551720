import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from 'react-bootstrap/Image';
import InfoIcon from 'images/info-icon.png';


const renderTooltip = (props) => (
  <Tooltip id="documentation-tooltip" {...props}>
    See Documentation on how to use this tool
  </Tooltip>
);

const DocumentationLinkIcon = (props) => {
  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={renderTooltip}
    >
      <a
        className="ms-2"
        href={`/documentation/${props.article}/`}
      >
        <Image
          height={30}
          src={InfoIcon}
        />
      </a>
    </OverlayTrigger>
  );
};


export default DocumentationLinkIcon;
