import React from 'react';

const DisplayRecipients = (props) => {
  const {formValues} = props;

  const FlightTag = (props) => {
    const {flight} = props;

    return (
      <span className='badge bg-primary m-2'>
        {flight.flightNumber} {flight.origin}-{flight.destination}<br/>
        {flight.flightDate}
      </span>
    );
  };

  const displayExcludeDuration = () => {
    let time = parseFloat(formValues.airport.excludeDuration);
    let hours = Math.floor(time);
    let min = (time - hours) * 60;
    return `${hours ? `${hours} hours ` : ''}${min ? `${min} minutes` : ''}`;
  };

  switch (formValues.notifyBy) {
    case 'pnrs':
      return (
        <div className="form-group text optional disabled notification_recipients form-group-valid">
          <label className="text optional disabled" htmlFor="notification_recipients">
            PNRs
          </label>
          <textarea className="form-control is-valid text optional disabled" disabled="disabled"
                    value={formValues.pnrs || ''}/>
          <small className="form-text text-muted">
            Comma separated list of PNRs to notify. Example: "ABC123,DEF456"
          </small>
        </div>
      );
    case 'flights':
      return (
        <div id="flight-fields">
          <h4>Flights</h4>
          <div className="hide-scroll" style={{maxHeight: '12vh'}}>
            {formValues.flights.map((flight, index) => <FlightTag flight={flight} key={index}/>)}
          </div>
        </div>
      );
    case 'thruFlights':
      return (
        <div id='thru-flight-fields'>
          <h4>Thru Flights</h4>
          <div className='hide-scroll' style={{maxHeight: '12vh'}}>
            {formValues.thruFlights.map((flight, index) => <FlightTag flight={flight} key={index}/>)}
          </div>
        </div>
      );
    case 'airport':
      return (
        <div id='airport-fields' className='mb-2'>
          <h4>Airport</h4>
          <span className='badge bg-primary mb-2' style={{fontSize: '0.875em'}}>
            <div>{formValues.airport.station}</div>
            <div>{displayExcludeDuration()} from now to midnight</div>
          </span>
        </div>
      );
    default:
      return (<></>);
  }
};

export default DisplayRecipients;
