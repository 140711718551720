const fire = (particleRatio, opts) => {
  // defaults
  const count = 200;
  const defaults = {
    origin: { y: 0.7 }
  };

  confetti(Object.assign({}, defaults, opts, {
    particleCount: Math.floor(count * particleRatio)
  }));
};

const fireConfetti = () => {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export const displaySuccessMessage = (message) => {
  document.getElementById('flash-alerts').innerHTML = `<div class="mb-3 alert alert-success alert-dismissible fade show" role="alert">
      <strong>Hooray!</strong> ${message}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>`;

  fireConfetti();
};

export const displayErrorMessage = (message, errors) => {
  if (errors && errors.length > 0){
    message += `<br/>-${errors.join('<br/>-')}`;
  }
  
  document.getElementById('flash-alerts').innerHTML = `<div class="mb-3 alert alert-danger alert-dismissible fade show" role="alert">
      <strong>Whoops!</strong> ${message}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>`;
};

export const closeAlert = () => {
  document.getElementById('flash-alerts').innerHTML = '';
};
