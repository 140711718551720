import React from 'react';

const ImagePreview = (props) => {
  const {image, onClose} = props;

  return (
    <div className='upload-image-preview' style={{backgroundImage: `url(${image})`}}>
      {onClose && <button id='close' type='button' onClick={onClose}>X</button>}
    </div>
  );
};

export default ImagePreview;
