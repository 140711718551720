import React from 'react';
import {Form} from 'react-bootstrap';

import RowSelectBox from './RowSelectBox';

const MultiSelect = (props) => {
  const {selected, setSelected, options} = props;

  const boxChecked = (value) => {
    return selected.includes(value);
  };

  const checkBox = (value) => {
    setSelected((oldValues) => {
      const newValues = Array.from(oldValues);
      const index = newValues.indexOf(value);
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(value);
      }
      return newValues;
    });
  };

  const allChecked = () => {
    return selected && selected.length === options.length;
  };

  const checkAll = () => {
    if (allChecked()) {
      setSelected([]);
    } else {
      let allValues = options.map((option) => option.value);
      setSelected(allValues);
    }
  };

  return (
    <div>
      <Form.Group className='form-check selectable-row mb-0' onClick={checkAll}>
        <input type="checkbox" readOnly={true} className="form-check-input multiselect-all-select"
               checked={allChecked()}/>
        <Form.Label className='form-check-label multiselect-all-label' style={{width: '100%'}}>ALL</Form.Label>
      </Form.Group>

      <div className='scrollable'>
        {options?.map((option, index) => (
          <div key={index}>
            <RowSelectBox checked={boxChecked(option.value)} label={option.label}
                          onCheck={() => checkBox(option.value)}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSelect;
