import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const MessagePreview = (props) => {
  const {show, handleClose, type, formValues, supportedVariables} = props;

  const label = () => {
    switch (type) {
      case 'email':
        return 'Email';
      case 'push':
        return 'Push Notification';
      case 'text':
        return 'Text Message';
    }
  };

  const replaceSupportedVariables = (message) => {
    if (!message) {
      return message;
    }

    let newMessage = message;

    supportedVariables.forEach((variable) => {
      newMessage = newMessage.replaceAll(`{{${variable.value}}}`, variable.example);
    });

    return newMessage;
  };

  const Body = () => {
    switch (type) {
      case 'email':
        return (
          <>
            <div style={{textAlign: 'center'}} id='preview-header'>
              <img height='50px' src="https://ik.imagekit.io/breeze/images/logos/breeze-light-transparent.png" alt=""/>
            </div>

            <div>
              <b>Subject:</b> {replaceSupportedVariables(formValues.emailSubject)}
            </div>

            <div dangerouslySetInnerHTML={{__html: replaceSupportedVariables(formValues.emailBody)}}/>

            <div className='text-center mt-3' id='preview-footer'>
              <div className='footer text-muted'>
                <p>
                  Breeze Airways <br/>
                  6340 S 3000 E Suite 400, Cottonwood Heights, UT 84121
                </p>
              </div>
            </div>
          </>
        );
      case 'push':
        return (
          <>
            <div>
              <b>Subject:</b> {replaceSupportedVariables(formValues.pushSubject)}
            </div>
            <div>
              <b>Click URL:</b> {replaceSupportedVariables(formValues.pushClickAction)}
            </div>
            <div>
              {replaceSupportedVariables(formValues.pushBody)}
            </div>
          </>
        );
      case 'text':
        return (
          <p>
            {replaceSupportedVariables(formValues.textMessage)}
          </p>
        );
      default:
        return (<></>);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{label()} Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This is a preview of what the {label()} will look like. Any variables have been replaced by example values.
        </p>
        <hr/>

        <Body/>

      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessagePreview;
