import React, {useRef, useState, useEffect} from 'react';
import {Button, Overlay, Tooltip} from 'react-bootstrap';

const ButtonWithTip = (props) => {
  const {children, className, disabled, tip, tipDisabled, variant, type, onClick, placement} = props;

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const hideTip = () => {
    setShow(false);
  };

  useEffect(() => {
    if (tipDisabled){
      hideTip();
    }
  }, [tipDisabled]);

  const showTip = (event) => {
    target.current = event.target;
    if (tipDisabled){
      return;
    }

    setShow(true);
  };

  const handleClick = (event) => {
    if (disabled){
      event.preventDefault();
      return;
    }

    if (onClick){
      onClick();
    }
  };

  return (<>
    <div className='d-inline-block' onMouseEnter={showTip} onMouseLeave={hideTip}>
      <Button
        className={className}
        variant={variant}
        onClick={handleClick}
        disabled={disabled}
        type={type}
      >
        {children}
      </Button>
    </div>
    <Overlay target={target.current} show={show} placement={placement || 'right'}>
      {(props) => (
        <Tooltip {...props}>
          {tip}
        </Tooltip>
      )}
    </Overlay>
  </>);
};

export default ButtonWithTip;
