import React from 'react';
import {Form} from 'react-bootstrap';

const RowSelectBox = (props) => {
  const {onCheck, checked, label} = props;

  const handleClick = (event) => {
    event.stopPropagation();
    onCheck();
  };

  return (
    <Form.Group className="form-check selectable-row" onClick={onCheck}>
      <input type="checkbox" readOnly={true} className="form-check-input multiselect-select" checked={checked}
             onClick={handleClick}/>
      <Form.Label className='multiselect-label'>
        {label}
      </Form.Label>
    </Form.Group>
  );
};

export default RowSelectBox;
