import React from 'react';
import {Button} from 'react-bootstrap';

import RichTextArea from '../shared/RichTextArea';

const Email = (props) => {
  const {formValues, handleChange, showPreview} = props;

  const disabled = () => {
    return !formValues.sendEmail;
  };

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='panelsStayOpen-headingEmail'>
        <button className='accordion-button fw-bolder collapsed' type='button' data-bs-toggle='collapse'
                data-bs-target='#panelsStayOpen-collapseOne' aria-expanded='false'
                aria-controls='panelsStayOpen-collapseOne'>
          Email
        </button>
      </h2>
      <div id='panelsStayOpen-collapseOne' className='accordion-collapse collapse'
           aria-labelledby='panelsStayOpen-headingEmail'>
        <div className='accordion-body'>
          <div className='form-group string optional notification_email_subject'>
            <label className='string optional' htmlFor='notification_email_subject'>
              Email Subject
            </label>
            <input className='form-control string optional' type='text' value={formValues.emailSubject || ''}
                   disabled={disabled()}
                   onChange={(event) => handleChange('emailSubject', event.target.value)}
            />
          </div>

          <div className="form-group text required notification_email_body">
            <label className="text" htmlFor="notification_email_body">
              Email Body
            </label>
            <RichTextArea disabled={disabled()} value={formValues.emailBody || ''}
                          onChange={(value) => handleChange('emailBody', value)}/>
          </div>

          <Button className='mt-2' onClick={() => showPreview('email')}>
            Preview Email
          </Button>
        </div>
      </div>
    </div>


  );
};

export default Email;
