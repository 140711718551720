import React, {useState, useEffect} from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {closeAlert, displayErrorMessage} from '../../packs/helpers/alerts';

const AirportForm = (props) => {
  const {setFlights, stations} = props;

  const defaultFormValues = {
    station: stations ? stations[0] : ''
  };

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);

  const formErrors = () => {
    let errors = [];

    if (!formValues.station){
      errors.push('You must select an airport');
    }

    return errors;
  };

  useEffect(() => {
    if (stations[0]){
      getFlights()
    }
  }, [])

  const getFlights = (event) => {
    event?.preventDefault();

    closeAlert();

    let errors = formErrors();
    if (errors.length > 0){
      displayErrorMessage('Invalid Search', errors);
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    fetch(`/ssr_portal/flights?station=${formValues.station}`)
      .then((response) => (response.json()))
      .then((result) => {
        if (result){
          setFlights(result);
        } else {
          displayErrorMessage('Failed to load flights');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setFormValues((oldValues) => ({...oldValues, [name]: value}));
  };

  return(
    <Form onSubmit={getFlights}>
      <Row>
        <Col>
          <Form.Group className="_base input-group-lg m-0">
            <Form.Select
              value={formValues.station}
              onChange={(event) => handleChange('station', event.target.value)}>
              <option value=''>Select an Airport</option>
              {stations?.map((station, index) =>
                <option key={index} value={station}>
                  {station}
                </option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Button type='submit' variant='primary' className='btn-lg' disabled={loading}>
            {loading ?
              <>
                <Spinner as='span' animation='grow' size='sm'/> Searching...
              </>
              : 'Search'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AirportForm;
