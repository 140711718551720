import React, {useState} from 'react';
import AutocompleteSuggestionList from "./AutocompleteSuggestionList";
import DropDownButton from "./DropDownButton";

const Autocomplete = (props) => {
  const {placeholder, required, suggestions, onSelect} = props;

  const [userInput, setUserInput] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange = (event) => {
    const inputText = event.currentTarget.value;

    const filteredOptions = suggestions.filter((suggestion) => {
      return suggestion.value.toLowerCase().indexOf(inputText.toLowerCase()) > -1;
    })

    setActiveSuggestion(0);
    setFilteredSuggestions(filteredOptions);
    setShowSuggestions(true);
    setUserInput(inputText);
  }

  const indexOfActiveSuggestion = (suggestionValue) => {
    const foundSug = suggestions.find((sug) => sug.value === suggestionValue);
    return suggestions.indexOf(foundSug);
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const selectedSuggestion = filteredSuggestions[activeSuggestion];

      setShowSuggestions(false);
      setUserInput(selectedSuggestion.value);
    } else if (event.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (event.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  }

  const handleOnClick = (event) => {
    const selectedSuggestion = event.currentTarget.dataset.value;

    setActiveSuggestion(indexOfActiveSuggestion(selectedSuggestion));
    setFilteredSuggestions(suggestions);
    setShowSuggestions(false);
    setUserInput(selectedSuggestion);
    onSelect(selectedSuggestion);
  }

  const handleDropDownClick = (event) => {
    setShowSuggestions(!showSuggestions);
  }

  return (
    <>
      <div className="d-flex">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          required={required}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={userInput}
        />
        <DropDownButton onClick={handleDropDownClick}/>
      </div>
      <AutocompleteSuggestionList
        showSuggestions={showSuggestions}
        suggestions={filteredSuggestions}
        activeSuggestion={activeSuggestion}
        handleOnClick={handleOnClick}
      />
    </>
  )
}

export default Autocomplete;
