import React from 'react';
import {Table} from 'react-bootstrap';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const Flights = (props) => {
  const {flights, selectFlight, departing} = props;

  const formatDate = (date) => {
    return dayjs(date).format('MMM D, hh:mm A');
  };

  if (flights && flights.length > 0){
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Flight Number</th>
            <th>{departing ? 'Destination' : 'Origin'}</th>
            <th>{departing ? 'Departure Time' : 'Arrival Time'}</th>
          </tr>
        </thead>
        <tbody>
          {flights?.map((flight, index) => (
            <tr key={index} onClick={() => selectFlight(flight)}>
              <td>{flight.flightNumber}</td>
              <td>{departing ? flight.scheduledArrivalAirport : flight.scheduledDepartureAirport}</td>
              <td>{departing ? formatDate(flight.stdLocal) : formatDate(flight.staLocal)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return(<>No flights found</>);
};

export default Flights;
