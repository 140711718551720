import React from 'react';

import FlightsForm from './FlightsForm';
import AirportForm from './AirportForm';

const RecipientForms = (props) => {
  const {formValues, handleChange, stations, formValid, defaultFlight} = props;

  const handleChangeAirport = (name, value) => {
    handleChange('airport', (oldAirport) => ({...oldAirport, [name]: value}));
  };

  switch (formValues.notifyBy) {
    case 'pnrs':
      return (
        <div className='form-group text required pnrs_pnrs'>
          <label className='text required' htmlFor='pnrs_pnrs'>
            PNRs <abbr title='required'>*</abbr>
          </label>
          <textarea className='form-control text required' required='required'
                    aria-required='true' value={formValues.pnrs}
                    onChange={(event) => handleChange('pnrs', event.target.value)}/>
          <small className='form-text text-muted'>
            Comma separated list of PNRs to notify. Example: "ABC123,DEF456"
          </small>
        </div>
      );
    case 'flights':
      return (
        <FlightsForm
          message='*Will not notify passengers on Thru Flights'
          formName='flights'
          formValues={formValues}
          handleChange={handleChange}
          stations={stations}
          formValid={formValid}
          defaultFlight={defaultFlight}
        />
      );
    case 'airport':
      return (
        <AirportForm stations={stations} formValues={formValues.airport} handleChange={handleChangeAirport}/>
      );
    case 'thruFlights':
      return (
        <FlightsForm
          message='*Will only notify passengers on all legs of the Flight'
          formName='thruFlights'
          formValues={formValues}
          handleChange={handleChange}
          stations={stations}
          formValid={formValid}
          defaultFlight={defaultFlight}
        />
      );
    default:
      return (
        <>
          {formValues.notifyBy} is unknown
        </>
      );
  }
};

export default RecipientForms;
