import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import dayjs from 'dayjs';

const Templates = (props) => {
  const {templates, onTemplateSelected, templatesUrl} = props;

  const [searchBy, setSearchBy] = useState('');

  const onClearSearch = () => {
    setSearchBy('');
  };

  const templatesInSearch = () => {
    if (searchBy) {
      return templates.filter((template) => (
        Object.keys(template).some((key) => {
          return template[key].toString().includes(searchBy);
        })
      ));
    }
    return templates;
  };

  const InnerTemplates = (props) => {
    const {header} = props;

    return (<>
      {header && <h3>Templates</h3>}

      <div className="d-flex mb-2">
        <input value={searchBy || ''} onChange={(event) => setSearchBy(event.target.value)}
               className="form-control me-2" id="recent-search" type="search" placeholder="Search" aria-label="Search"/>
        <Button variant='outline-primary' onClick={onClearSearch}>
          Clear
        </Button>
      </div>

      <div className="list-group mb-2"
           style={{
             maxHeight: '40em',
             overflow: 'auto',
             borderBottom: '1px solid #cecece',
             borderTop: '1px solid #cecece'
           }}>
        {templatesInSearch()?.map((template, index) => (
          <div key={index} onClick={(event) => onTemplateSelected(template, event)}
               className='list-group-item list-group-item-action'>
            <h5 className='mb-1'>{template.template_name}</h5>
            <div className='d-flex w-100 justify-content-between'>
              <small className='text-uppercase'>{dayjs(template.updated_at).format('MMM D, YYYY h:mm A')}</small>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center">
        <a className="btn btn-primary" href={templatesUrl}>View All</a>
      </div>
    </>);

  };

  return (<>
    <div className='accordion-item hide-on-computer'>
      <h2 className='accordion-header'>
        <button className='accordion-button fw-bolder' type='button' data-bs-toggle='collapse'
                data-bs-target='#templatesCollapse' aria-expanded='true' aria-controls='templatesCollapse'>
          Templates
        </button>
      </h2>

      <div id='templatesCollapse' className='accordion-collapse show'>
        <InnerTemplates/>
      </div>
    </div>

    <div className='hide-on-phone'>
      <InnerTemplates header/>
    </div>
  </>);
};

export default Templates;
