import React from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {displayErrorMessage} from '../../../packs/helpers/alerts';

import MultiSelect from '../../shared/MultiSelect';

const Recipients = (props) => {
  const {handleChange, formValues, stations, onResetPressed, onNextPressed, aircraftTypes} = props;
  const rankOptions = [
    {value: 'CA', label: 'Captain'},
    {value: 'FO', label: 'First Officer'},
    {value: 'CQFO', label: 'Captain Qualified First Officer'},
    {value: 'FA', label: 'Flight Attendant'}
  ];

  const aircraftTypeOptions = aircraftTypes.map((ac) => { 
    return {value: ac.iata, label: ac.name}
  });

  const baseOptions = stations.map((station) => ({value: station, label: station}));

  const formValid = () => {
    return formValues.ranks.length > 0 && formValues.bases.length > 0 && formValues.aircraftTypes.length > 0;
  };

  const handleNextPressed = () => {
    if (!formValid()) {
      displayErrorMessage('You must select at least 1 base, 1 rank and 1 aircraft type');
      return;
    }

    onNextPressed();
  };

  return (
    <div>
      <Row className='mb-2'>
        <Col className='col-12 col-md-4'>
          <h3>
            Ranks:
          </h3>
          <MultiSelect options={rankOptions} selected={formValues.ranks}
                       setSelected={(values) => handleChange('ranks', values)}/>
        </Col>
        <Col>
          <h3>
            Bases:
          </h3>
          {baseOptions.length > 0 ?
            <MultiSelect options={baseOptions} selected={formValues.bases}
                         setSelected={(values) => handleChange('bases', values)}/>
            :
            <div>No Bases to Select.<br/>Manage Bases below</div>
          }
        </Col>
        <Col>
          <h3>
            Aircraft Types:
          </h3>
          <MultiSelect options={aircraftTypeOptions} selected={formValues.aircraftTypes}
                       setSelected={(values) => handleChange('aircraftTypes', values)}/>
        </Col>
      </Row>

      <div>
        <Button variant='secondary' onClick={onResetPressed} className='m-1'>Reset Form</Button>
        <Button onClick={handleNextPressed} className='m-1'>Next</Button>
      </div>


      <p className='text-center text-muted mt-5'>
        To view all crew notification templates. Please use the link below.
      </p>

      <div className='d-flex justify-content-center'>
        <a className='btn btn-primary' href='/crew_notification_templates'>View Templates</a>
      </div>

      <p className='text-center text-muted mt-5'>
        To manage the bases, please use the link below.
      </p>
      <div className='d-flex justify-content-center mt-3'>
        <a className='btn btn-primary' href='/airport_bases'>Bases Management</a>
      </div>

    </div>
  );
};

export default Recipients;
