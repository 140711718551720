import React from 'react';
import MessageForm from '../MessageForm';

const Messages = (props) => {
  const {
    formValues,
    handleChange,
    templates,
    onTemplateSelected,
    onBackPressed,
    onSubmitPressed,
    onResetPressed
  } = props;

  const supportedVariables = [
    {label: 'Base', value: 'base', example: 'CHS'},
    {label: "Crew Member's Rank", value: 'rank', example: 'FA'},
    {label: "Crew Member's Aircraft Type", value: 'aircraft_type', example: '223'},
    {label: "Crew Member's Firstname", value: 'first_name', example: 'Ethan'},
    {label: "Crew Member's Lastname", value: 'last_name', example: 'Johnson'}
  ];

  const Ranks = () => {
    return (<>
      <h6>
        Ranks:
      </h6>
      <p> {formValues.ranks.join(', ')}</p>
    </>);
  };

  const Bases = () => {
    return (<>
      <h6>
        Bases:
      </h6>
      <p>{formValues.bases.join(', ')}</p>
    </>);
  };

  const AircraftTypes = () => {
    return (<>
      <h6>
        Aircraft Types:
      </h6>
      <p>{formValues.aircraftTypes.join(', ')}</p>
    </>);
  };

  return (
    <MessageForm
      formValues={formValues} handleChange={handleChange} onBackPressed={onBackPressed}
      onSubmitPressed={onSubmitPressed}
      onResetPressed={onResetPressed} messageTypes={['text', 'email']} supportedVariables={supportedVariables}
      templates={templates} onTemplateSelected={onTemplateSelected} templatesUrl={'/crew_notification_templates'}
      supportedVariableProps={{
        description:
          <p>
            The following variables can be used in any subject or message.
            See <a href="/documentation/crew_notifications#message-variables">Message Variables</a> in the documentation for more information.
          </p>,
        example:
          <p className="mb-0">Example:
            <em>"Hi {'{{first_name}}'}, new flights at {'{{base}}'} are ready for selection."</em>.
            Will
            result in:
            <em>"Hi Ethan, new flights at CHS are ready for selection."</em>
          </p>
      }}
    >
      <>
        <h4>Recipients</h4>
        <Ranks/>
        <Bases/>
        <AircraftTypes/>
      </>
    </MessageForm>
  );
};

export default Messages;
