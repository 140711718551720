import React from 'react';
import {Button} from 'react-bootstrap';

const TextMessage = (props) => {
  const {formValues, handleChange, showPreview} = props;

  const displayWordCount = () => {
    if (formValues?.textMessage) {
      return formValues.textMessage.length;
    }
    return 0;
  };

  const disabled = () => {
    return !formValues.sendText;
  };

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='panelsStayOpen-headingText'>
        <button className='accordion-button fw-bolder collapsed' type='button' data-bs-toggle='collapse'
                data-bs-target='#panelsStayOpen-collapseText' aria-expanded='false'
                aria-controls='panelsStayOpen-collapseText'>
          Text Message
        </button>
      </h2>
      <div id='panelsStayOpen-collapseText' className='accordion-collapse collapse'
           aria-labelledby='panelsStayOpen-headingText'>
        <div className='accordion-body'>
          <div>
            <div className='form-group text optional notification_text_message'>
              <label className='text optional' htmlFor='notification_text_message'>
                Text Message
              </label>
              <textarea
                value={formValues.textMessage || ''}
                onChange={(event) => handleChange('textMessage', event.target.value)}
                className='form-control text optional'
                disabled={disabled()} maxLength='320'/>
            </div>
            <small className='form-text text-muted'>
              Total characters: {displayWordCount()}/320
            </small>
          </div>

          <Button className='mt-2' onClick={() => showPreview('text')}>
            Preview Text Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextMessage;
