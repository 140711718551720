import React from 'react';
import {Button} from 'react-bootstrap';

const PushNotification = (props) => {
  const {formValues, handleChange, showPreview} = props;

  const displaySubjectWordCount = () => {
    if (formValues?.pushSubject) {
      return formValues.pushSubject.length;
    }
    return 0;
  };

  const displayBodyWordCount = () => {
    if (formValues?.pushBody) {
      return formValues.pushBody.length;
    }
    return 0;
  };

  const disabled = () => {
    return !formValues.sendPush;
  };

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='panelsStayOpen-headingPush'>
        <button className='accordion-button fw-bolder collapsed' type='button' data-bs-toggle='collapse'
                data-bs-target='#panelsStayOpen-collapsePush' aria-expanded='false'
                aria-controls='panelsStayOpen-collapsePush'>
          Push Notification
        </button>
      </h2>
      <div id='panelsStayOpen-collapsePush' className='accordion-collapse collapse'
           aria-labelledby='panelsStayOpen-headingPush'>
        <div className='accordion-body'>

          <div className="mb-2">
            <div className="form-group string optional notification_push_subject">
              <label className="string optional disabled" htmlFor="notification_push_subject">
                Push Subject
              </label>
              <input className="form-control string optional" disabled={disabled()} value={formValues.pushSubject || ''}
                     onChange={(event) => handleChange('pushSubject', event.target.value)}
                     maxLength="50" size="50" type="text"/>
            </div>
            <small className="form-text text-muted">
              Subject characters: {displaySubjectWordCount()}/50
            </small>
          </div>

          <div className="form-group string optional notification_push_click_action">
            <label className="string optional" htmlFor="notification_push_click_action">
              Push Click Action (URL)
            </label>
            <input className="form-control string optional" disabled={disabled()} type="text"
                   value={formValues.pushClickAction || ''}
                   onChange={(event) => handleChange('pushClickAction', event.target.value)}
            />
            <small className="form-text text-muted">Example: "https://flybreeze.com/my-trips"</small>
          </div>

          <div>
            <div className="form-group text optional notification_push_body">
              <label className="text optional disabled" htmlFor="notification_push_body">
                Push Body
              </label>
              <textarea className="form-control text optional" disabled={disabled()} maxLength="320"
                        value={formValues.pushBody || ''}
                        onChange={(event) => handleChange('pushBody', event.target.value)}
              />
            </div>
            <small className="form-text text-muted">Total characters: {displayBodyWordCount()}/320</small>
          </div>

          <Button className='mt-2' onClick={() => showPreview('push')}>
            Preview Push Notification
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PushNotification;
