import React from 'react';
import CopyButton from '../shared/CopyButton';

const SupportedVariables = (props) => {
  const {variables, description, example} = props;

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id='panelsStayOpen-headingVariables'>
        <button className='accordion-button fw-bolder collapsed' type='button' data-bs-toggle='collapse'
                data-bs-target='#panelsStayOpen-collapseVariables' aria-expanded='true'
                aria-controls='panelsStayOpen-collapseVariables'>
          Supported Variables
        </button>
      </h2>
      <div id='panelsStayOpen-collapseVariables' className='accordion-collapse collapse'
           aria-labelledby='panelsStayOpen-headingVariables'>
        <div className='accordion-body'>
          <div>
            {description}
            {variables.map((variable, index) => (
              <p key={index}>
                <strong>{variable.label}:</strong> <code>{`{{${variable.value}}}`}</code>&ensp;
                <CopyButton copyValue={`{{${variable.value}}}`}/>
              </p>
            ))}
            {example}
          </div>

        </div>
      </div>
    </div>
  );
};

export default SupportedVariables;
