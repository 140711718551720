import React from 'react';
import {Col, Form} from 'react-bootstrap';
import Autocomplete from "./Autocomplete";

const Field = (props) => {
  const {type, name, options, required, onChange, formValues, suggestions, placeholder} = props;

  if (type === 'text') {
    return (
      <input
        placeholder={placeholder}
        className='form-control'
        type='text'
        required={required}
        value={formValues[name] || ''}
        onChange={(event) => onChange(name, event.target.value)}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <textarea
        placeholder={placeholder}
        className='form-control'
        required={required}
        value={formValues[name] || ''}
        onChange={(event) => onChange(name, event.target.value)}
      />
    );
  }

  if (type === 'number') {
    return (
      <input
        placeholder={placeholder}
        className='form-control'
        type='number'
        required={required}
        value={formValues[name] || '0'}
        onChange={(event) => onChange(name, event.target.value)}
      />
    );
  }

  if (type === 'bool') {
    return (
      <input
        placeholder={placeholder}
        id='check-input'
        className='form-check-input'
        required={required}
        type='checkbox'
        checked={!!formValues[name]}
        onChange={(event) => onChange(name, !formValues[name])}
      />
    );
  }

  if (type === 'select') {
    return (
      <select
        placeholder={placeholder}
        className='form-select'
        required={required}
        value={formValues[name] || options[0]?.value}
        onChange={(event) => onChange(name, event.target.value)}
      >
        {options.map((option, index) =>
          <option key={index} value={option.value} disabled={option.disabled}>{option.label || option.value}</option>
        )}
      </select>
    );
  }

  if (type === 'autocomplete') {
    return (
      <Autocomplete
        placeholder={placeholder}
        required={required}
        suggestions={suggestions}
        onSelect={(value) => onChange(name, value)}
      />
    );
  }

  return (<></>
  );
};

const FormBuilder = (props) => {
  const {formValues, onChange, fields} = props;

  return (
    <>
      {fields.map((field, index) => (
        <Col md={6} xs={12} key={index} className='mt-1'>
          <Form.Group>
            <Form.Label className={field.inline && 'inline-label'}>
              <span>{field.label} {field.required && <abbr title='required'>*</abbr>}</span>
              {field.inline &&
                <Field
                  {...field}
                  key={index}
                  onChange={onChange}
                  formValues={formValues || {}}
                />
              }
            </Form.Label>
            {!field.inline &&
              <Field
                {...field}
                key={index}
                onChange={onChange}
                formValues={formValues || {}}
              />
            }
          </Form.Group>
        </Col>
      ))}
    </>
  );
};

export default FormBuilder;
