import React, {useState, useEffect} from 'react';
import Recipients from './Recipients';
import Messages from './Messages';
import NotificationTool from '../NotificationTool';

const CrewNotificationTool = (props) => {
  const {stations, templates, selectedTemplate, authenticityToken, aircraftTypes} = props;
  const defaultFormValues = {
    ranks: [],
    bases: [],
    aircraftTypes: [],
    sendEmail: false,
    sendText: false
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const onSubmitPressed = () => {
    return fetch('/crew_notifications/send_messages', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      body: JSON.stringify(formValues)
    });
  };

  const onTemplateSelected = (template) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      emailSubject: template?.email_subject || '',
      emailBody: template?.email_body?.body || '',
      textMessage: template?.text_message || ''
    }));
  };

  useEffect(() => {
    if (selectedTemplate) {
      onTemplateSelected(selectedTemplate);
    }
  }, []);

  return (
    <NotificationTool
      title='Crew Notification Tool'
      documentation='crew_notifications'
      localStorageName='crewNotificationToolValues'
      formValues={formValues}
      setFormValues={setFormValues}
      Recipients={Recipients}
      recipientProps={{stations}}
      Messages={Messages}
      messageProps={{templates, onTemplateSelected}}
      defaultFormValues={defaultFormValues}
      onSubmit={onSubmitPressed}
      aircraftTypesProps={aircraftTypes}
    />
  );
};

export default CrewNotificationTool;
