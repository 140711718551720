import React, {useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import ImagePreview from './ImagePreview';

const ImageButton = (props) => {
  const {multiple, onChange} = props;

  return (
    <Col className='m-1'>
      <input id='actual-photo-upload' type='file' onChange={onChange} hidden multiple={multiple}
             accept='image/png,image/jpeg,image/jpg,image/gif'/>
      <label htmlFor='actual-photo-upload' className='camera-button'/>
    </Col>
  );
};

const ImageUpload = (props) => {
  const {onChange, onClose, multiple, maxFiles} = props;

  const [imagePreviews, setImagePreviews] = useState([]);
  const [files, setFiles] = useState([]);

  const readFiles = (files) => {
    let unreadFiles = Array.from(files);
    let reader = new FileReader();

    reader.onload = () => {
      setImagePreviews((oldImages) => (oldImages.concat([reader.result])));
      if (files.length > 0) {
        reader.readAsDataURL(unreadFiles.shift());
      }
    };

    reader.readAsDataURL(unreadFiles.shift());
  }

  const handleChange = (event) => {
    let newFiles = Array.from(event.target.files).splice(0, maxFiles - files.length);
    let allFiles = files.concat(newFiles);

    readFiles(newFiles);

    setFiles(allFiles);

    onChange(event, allFiles);
  }

  const handleClose = (event, index) => {
    if (onClose) {
      onClose(index);
    }

    if (onChange) {
      const newFiles = files;
      newFiles.splice(index, 1);
      event.target.files = newFiles;
      onChange(event);
    }

    setImagePreviews((oldImages) => {
      const newImages = Array.from(oldImages);
      newImages.splice(index, 1);
      return newImages;
    });
  };

  return (
    <Row id='image-uploader' className='d-inline-flex'>
      {imagePreviews.map((image, index) =>
        <Col className='m-1' key={index}>
          <ImagePreview image={image} onClose={(event) => handleClose(event, index)}/>
        </Col>
      )}
      <Col xs={12}>
        {((multiple && files.length < maxFiles) || imagePreviews.length === 0) &&
          <ImageButton onChange={handleChange} multiple={multiple}/>
        }
      </Col>
    </Row>
  );
};

export default ImageUpload;
