import React from 'react';
import {Button, Row, Col} from 'react-bootstrap';

const FlightForm = (props) => {
  const {formValues, handleChange, stations, handleRemoveFlight} = props;

  return (
    <Row>
      <div className="col-auto">
        <label className="string required">
          Flight date <abbr title="required">*</abbr>
        </label>
        <input value={formValues.flightDate || ''} className="form-control" type="date"
               onChange={(event) => handleChange('flightDate', event.target.value)}/>
      </div>

      <Col className='col-auto'>
        <div className="form-group select required">
          <label className="select required">
            Origin <abbr title="required">*</abbr>
          </label>
          <select value={formValues.origin || ''} className="form-control select required"
                  onChange={(event) => handleChange('origin', event.target.value)}>
            {stations.map((station, index) => (
              <option value={station} key={index}>{station}</option>
            ))}
          </select>
        </div>
      </Col>
      <Col className="col-auto">
        <div className="form-group select required">
          <label className="select required">
            Destination <abbr title="required">*</abbr>
          </label>
          <select value={formValues.destination || ''} className="form-control select required"
                  onChange={(event) => handleChange('destination', event.target.value)}>
            {stations.map((station, index) => (
              <option value={station} key={index}>{station}</option>
            ))}
          </select>
        </div>
      </Col>

      <Col className="col-auto">
        <div className="form-group string required">
          <label className="string required">
            Flight number <abbr title="required">*</abbr>
          </label>
          <input value={formValues.flightNumber || ''} className="form-control string required" required="required"
                 onChange={(event) => handleChange('flightNumber', event.target.value)}
                 aria-required="true" type="text"/>
          <small className="form-text text-muted">
            Do not include MX
          </small>
        </div>
      </Col>

      {handleRemoveFlight &&
        <Col className="col-auto d-flex align-items-center">
          <Button onClick={handleRemoveFlight} variant='danger' className="mb-2">
            Remove
          </Button>
        </Col>
      }
    </Row>
  );
};

export default FlightForm;
