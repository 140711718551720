import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';

import TextMessage from './TextMessage';
import Templates from './Templates';
import PushNotification from './PushNotification';
import Email from './Email';
import MessagePreview from './MessagePreview';
import SupportedVariables from './SupportedVariables';
import {displayErrorMessage} from '../../packs/helpers/alerts';

const MessageForm = (props) => {
  const {
    formValues,
    handleChange,
    onBackPressed,
    onResetPressed,
    onSubmitPressed,
    children,
    messageTypes,
    supportedVariables,
    supportedVariableProps,
    submitting,
    templates,
    onTemplateSelected,
    templatesUrl
  } = props;

  const [previewShow, setPreviewShow] = useState(false);
  const [previewType, setPreviewType] = useState('');

  const showPreview = (type) => {
    setPreviewType(type);
    setPreviewShow(true);
  };

  const hidePreview = () => {
    setPreviewShow(false);
  };

  const possibleTypes = [
    {
      type: 'text',
      label: 'Text Message',
      checkValue: 'sendText',
      component: <TextMessage key='text' formValues={formValues} handleChange={handleChange} showPreview={showPreview}/>
    },
    {
      type: 'push',
      label: 'Push Notification',
      checkValue: 'sendPush',
      component: <PushNotification key='push' formValues={formValues} handleChange={handleChange}
                                   showPreview={showPreview}/>
    },
    {
      type: 'email',
      label: 'Email',
      checkValue: 'sendEmail',
      component: <Email key='email' formValues={formValues} handleChange={handleChange} showPreview={showPreview}/>
    }
  ];

  const toggleMessageType = (type) => {
    let value = formValues[type];
    handleChange(type, !value);
  };

  const formValid = () => {
    if (!formValues.sendEmail && !formValues.sendPush && !formValues.sendText) {
      return [false, 'You must select at least one message type'];
    }

    if (
      (formValues.sendEmail && !(formValues.emailSubject && formValues.emailBody)) ||
      (formValues.sendPush && !(formValues.pushSubject && formValues.pushBody && formValues.pushClickAction)) ||
      (formValues.sendText && !(formValues.textMessage))
    ) {
      return [false, 'You must fill out all fields of any selected message type'];
    }

    return [true];
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const [valid, errorMessage] = formValid();
    if (!valid) {
      displayErrorMessage(errorMessage);
      return;
    }

    onSubmitPressed();
  };

  return (
    <div className='row'>
      <div className='col-12 col-md-8 order-1 order-md-0'>
        <Form onSubmit={handleSubmit}>
          <MessagePreview show={previewShow} handleClose={hidePreview} type={previewType} formValues={formValues}
                          supportedVariables={supportedVariables}/>

          {children}

          <h4>Messages</h4>
          <div className='row m-3'>
            {possibleTypes?.map((type, index) => {
              if (messageTypes?.includes(type.type)) {
                return (
                  <div key={index} className='col-12 col-md-6 col-lg-4'>
                    <div className='form-check form-switch m-0'>
                      <input className='form-check-input boolean optional'
                             onChange={(event) => toggleMessageType(type.checkValue)}
                             type='checkbox' checked={!!formValues[type.checkValue]}
                             id={`notification_${type.checkValue}`}/>
                      <label className='form-check-label boolean optional' htmlFor={`notification_${type.checkValue}`}>
                        {type.label}
                      </label>
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className='accordion mt-2' id='accordionPanelsStayOpenExample'>
            {possibleTypes?.map((type) => {
              if (messageTypes?.includes(type.type) && type.component) {
                return (type.component);
              }
            })}
            <SupportedVariables {...supportedVariableProps} variables={supportedVariables}/>
          </div>

          <Button variant='outline-secondary' onClick={onBackPressed} className='m-1'>Back</Button>
          <Button variant='secondary' onClick={onResetPressed} className='m-1'>Reset Form</Button>
          <Button type='submit' className='m-1' disabled={submitting}>Send Notification</Button>
        </Form>
      </div>

      <div className='col-12 col-md-4 mt-2 mt-md-0 order-0 order-md-1'>
        <Templates templates={templates} onTemplateSelected={onTemplateSelected}
                   templatesUrl={templatesUrl}/>
      </div>
    </div>
  );
};

export default MessageForm;
